import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box, TextField, Container, Paper, Button,
} from '@material-ui/core';
import { MUIEditorState } from 'react-mui-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FreeSoloBox from '../../../components/atoms/freeSoloBox/FreeSoloBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import Wysiwyg from '../../../components/atoms/wysiwyg/Wysiwyg';
import Validation from './validation';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import urls from '../../../constants/urls';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

import {
  actCallApiSaveNotice, actCallApiNoticeDetailSearch, actResetNoticeFlg,
  actCallApiInitNoticeList, actResetIsCopySuccess,
} from '../../../redux/master/notice/action';

const tmpForm = {
  id: null,
  company: null,
  title: '',
  category: '',
  content: '',
  draftContent: MUIEditorState.createEmpty(),
  startDate: '',
  endDate: '',
  version: null,
  createdAt: '',
  createdBy: null,
  updatedAt: '',
  updatedBy: null,
  companyName: '',
  createdByLastName: '',
  createdByFirstName: '',
  updatedByLastName: '',
  updatedByFirstName: '',
};

const tmpErrorMessage = {
  title: '',
  category: '',
  startDate: '',
  endDate: '',
  company: '',
};

class NoticeDetail extends React.Component {
  constructor(props) {
    super(props);
    let isMain = false;
    let userCompany = {};

    const { id } = props.location.state;
    if (props.common.user) {
      isMain = props.common.user.main;
      userCompany = {
        id: props.common.user.store.mstOrganizationCompanyId,
      };
    }

    this.breadcrumbsText = [
      {
        url: urls.MASTER.NOTICE_LIST,
        name: 'お知らせ一覧',
      },
      { name: 'お知らせ詳細' },
    ];

    if (id) {
      props.dispatch(
        actCallApiNoticeDetailSearch({ id }),
      );
    }
    props.dispatch(actCallApiInitNoticeList());
    tmpForm.company = userCompany;
    let successMessage = '';
    let isSuccessSnackOpen = false;
    if (this.props.notices.isCopySuccess) {
      successMessage = 'コピーが完了しました。';
      isSuccessSnackOpen = true;

      props.dispatch(actResetIsCopySuccess());
    }
    this.state = {
      notice: {
        ...tmpForm,
        id,
      },
      isMain,
      errorMessage: tmpErrorMessage,
      select: {
        companies: [],
        categories: [],
      },
      isSuccessSnackOpen,
      successMessage,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notices.data !== this.props.notices.data) {
      if (this.props.notices.data) {
        this.setData(this.props.notices.data);
      }
    }
    if (prevProps.notices.select !== this.props.notices.select) {
      if (this.props.notices.select) {
        this.setSelect(this.props.notices.select);
      }
    }
    if (prevProps.notices.isSaveSuccess !== this.props.notices.isSaveSuccess) {
      if (this.props.notices.isSaveSuccess) {
        this.setSuccess(this.props.notices.saveId);
      }
    }
  }

  setSelect(select) {
    this.setState({ select });
  }

  setSuccess(saveId) {
    this.setState({
      successMessage: '保存が完了しました',
      isSuccessSnackOpen: true,
    });
    this.props.dispatch(
      actCallApiNoticeDetailSearch({ id: saveId }),
    );
    this.props.dispatch(actCallApiInitNoticeList());
  }

  setData = (data) => {
    const conv = htmlToDraft(data.content);
    const contentState = ContentState.createFromBlockArray(
      conv.contentBlocks,
      conv.entityMap,
    );
    const draftContent = EditorState.createWithContent(contentState);
    this.setState({
      notice: {
        ...data,
        company: {
          id: data.companyId,
          name: data.companyName,
        },
        draftContent,
      },
    });
  };

  onSuccessSnackbarClose = () => {
    this.setState({ isSuccessSnackOpen: false });
  }

  onChange = (event) => {
    const { notice, errorMessage } = this.state;
    const { name, value } = event.target;
    this.setState({
      notice: {
        ...notice,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  onDateChangeNotice = (value, name) => {
    const { notice, errorMessage } = this.state;
    this.setState({
      notice: {
        ...notice,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  }

  onChangeContent = (e) => {
    // draftContent draft→HTML
    const { notice } = this.state;
    const { name, value } = e.target;
    const draftContent = convertToRaw(value.getCurrentContent());
    const content = draftToHtml(draftContent);
    this.setState({ notice: { ...notice, [name]: value, content } });
  };

  onClickUpsertNotice = () => {
    const { notice, errorMessage } = this.state;
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(notice).forEach((key) => {
      const msg = Validation.formValidate(key, notice[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!tempMessage.startDate && !tempMessage.endDate) {
      const msg = Validation.compareDateValidate(notice.startDate, notice.endDate);
      if (msg) {
        isError = true;
      }
      tempMessage.endDate = msg;
    }

    this.setState({ errorMessage: tempMessage });

    if (isError) {
      return;
    }
    this.props.dispatch(actResetNoticeFlg());
    this.props.dispatch(actCallApiSaveNotice({
      ...notice,
      companyId: notice.company && notice.company.id,
    }));
  }

  render() {
    const {
      notice, errorMessage, isMain, select, isSuccessSnackOpen, successMessage,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container maxWidth={false} style={{ paddingTop: '60px', paddingBottom: '40px' }}>
          <Paper elevation={0} variant="outlined">
            <Box p={3}>
              <Box display={isMain ? '' : 'none'}>
                <FormTitle title="加盟企業" isRequired />
                <SearchSelectBox options={select.companies} value={notice.company} onChange={this.onChange} name="company" error={errorMessage.company !== ''} />
                <FormErrorText>{errorMessage.company}</FormErrorText>
              </Box>
              <Box mt={3}>
                <FormTitle title="タイトル" isRequired />
                <TextField
                  name="title"
                  variant="outlined"
                  fullWidth
                  value={notice.title}
                  onChange={this.onChange}
                  error={errorMessage.title !== ''}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                <FormErrorText>{errorMessage.title}</FormErrorText>
              </Box>
              <Box mt={3}>
                <FormTitle title="コンテンツ" />
                <Wysiwyg
                  onChange={this.onChangeContent}
                  value={notice.draftContent}
                  name="draftContent"
                />
              </Box>
              <Box mt={3}>
                <FormTitle title="カテゴリ" isRequired />
                <FreeSoloBox
                  name="category"
                  disableClearable
                  error={errorMessage.category !== ''}
                  options={select.categories}
                  value={notice.category}
                  onChange={this.onChange}
                />
                <FormErrorText>{errorMessage.category}</FormErrorText>
              </Box>
              <Box mt={3}>
                <FormTitle title="公開日" isRequired />
                <Box display="flex" mt={1} alignItems="center">
                  <Box>
                    <Datepicker value={notice.startDate} handleChange={(_, value) => this.onDateChangeNotice(value, 'startDate')} error={errorMessage.startDate !== ''} />
                    <FormErrorText>{errorMessage.startDate}</FormErrorText>
                  </Box>
                  <Box mr={4} ml={4}>～</Box>
                  <Box>
                    <Datepicker value={notice.endDate} handleChange={(_, value) => this.onDateChangeNotice(value, 'endDate')} error={errorMessage.endDate !== ''} />
                    <FormErrorText>{errorMessage.endDate}</FormErrorText>
                  </Box>
                </Box>
              </Box>
              <Box style={{ display: notice.id ? '' : 'none' }}>
                <Box mt={3}>
                  <FormTitle title="作成者" />
                  <Box display="flex" mt={1} alignItems="center">
                    <Box>
                      {`${notice.createdAt} ${notice.createdBy}`}
                    </Box>
                  </Box>
                </Box>
                <Box mt={3}>
                  <FormTitle title="更新者" />
                  <Box display="flex" mt={1} alignItems="center">
                    <Box>
                      {`${notice.updatedAt} ${notice.updatedBy}`}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Box align="center" my={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={this.onClickUpsertNotice}
            >
              保存する
            </Button>
          </Box>
        </Container>
        <SuccessSnackbar
          open={isSuccessSnackOpen}
          handleClose={this.onSuccessSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  notices: state.notices,
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(NoticeDetail));
