// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_NOTICE_LIST: 'CALL_API_INIT_NOTICE_LIST',
  CALL_API_INIT_NOTICE_LIST_SUCCESS: 'CALL_API_INIT_NOTICE_LIST_SUCCESS',
  CALL_API_NOTICE_LIST_SEARCH: 'CALL_API_NOTICE_LIST_SEARCH',
  CALL_API_NOTICE_LIST_SEARCH_SUCCESS: 'CALL_API_NOTICE_LIST_SEARCH_SUCCESS',
  CALL_API_NOTICE_DETAIL_SEARCH: 'CALL_API_NOTICE_DETAIL_SEARCH',
  CALL_API_NOTICE_DETAIL_SEARCH_SUCCESS: 'CALL_API_NOTICE_DETAIL_SEARCH_SUCCESS',
  CALL_API_SAVE_NOTICE: 'CALL_API_SAVE_NOTICE',
  CALL_API_SAVE_NOTICE_SUCCESS: 'CALL_API_SAVE_NOTICE_SUCCESS',
  CALL_API_DELETE_NOTICE: 'CALL_API_DELETE_NOTICE',
  CALL_API_DELETE_NOTICE_SUCCESS: 'CALL_API_DELETE_NOTICE_SUCCESS',
  RESET_NOTICE_FLG: 'RESET_NOTICE_FLG',
  CALL_API_COPY_NOTICE: 'CALL_API_COPY_NOTICE',
  CALL_API_COPY_NOTICE_SUCCESS: 'CALL_API_COPY_NOTICE_SUCCESS',
  RESET_IS_COPY_SUCCESS: 'RESET_IS_COPY_SUCCESS',
};

// #region Type
export const actCallApiInitNoticeList = () => ({
  type: ACTION_TYPE.CALL_API_INIT_NOTICE_LIST,
});

export const actCallApiInitNoticeListSuccess = (select) => ({
  type: ACTION_TYPE.CALL_API_INIT_NOTICE_LIST_SUCCESS,
  select,
});

export const actCallApiNoticeListSearch = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_NOTICE_LIST_SEARCH,
  searchCondition,
});

export const actCallApiNoticeListSearchSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_NOTICE_LIST_SEARCH_SUCCESS,
  datas,
});

export const actCallApiNoticeDetailSearch = (param) => ({
  type: ACTION_TYPE.CALL_API_NOTICE_DETAIL_SEARCH,
  param,
});

export const actCallApiNoticeDetailSearchSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_NOTICE_DETAIL_SEARCH_SUCCESS,
  data,
});

export const actCallApiSaveNotice = (notice) => ({
  type: ACTION_TYPE.CALL_API_SAVE_NOTICE,
  notice,
});

export const actCallApiSaveNoticeSuccess = (saveId) => ({
  type: ACTION_TYPE.CALL_API_SAVE_NOTICE_SUCCESS,
  saveId,
});

export const actCallApiDeleteNotice = (deleteList) => ({
  type: ACTION_TYPE.CALL_API_DELETE_NOTICE,
  deleteList,
});

export const callApiDeleteNoticeSuccess = () => ({
  type: ACTION_TYPE.CALL_API_DELETE_NOTICE_SUCCESS,
  isDeleteSuccess: true,
});

export const actResetNoticeFlg = () => ({
  type: ACTION_TYPE.RESET_NOTICE_FLG,
});

export const actCallApiCopyNotice = (id) => ({
  type: ACTION_TYPE.CALL_API_COPY_NOTICE,
  id,
});

export const callApiCopyNoticeSuccess = (copyNoticeId) => ({
  type: ACTION_TYPE.CALL_API_COPY_NOTICE_SUCCESS,
  copyNoticeId,
  isCopySuccess: true,
});

export const actResetIsCopySuccess = () => ({
  type: ACTION_TYPE.RESET_IS_COPY_SUCCESS,
  isCopySuccess: false,
});
