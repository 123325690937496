import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  TableSortLabel, Toolbar, Typography,
  Paper, Checkbox, Tooltip,
  IconButton, Button, withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../confirmDialog/ConfirmDialog';
import Pagination from '../../atoms/pagination/Pagination';
import { getComparator, stableSort, getSelected } from '../../../helpers/tableSort.helper';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
});

class DeleteDataTable extends React.Component {
  constructor() {
    super();
    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      deleteConfopen: false,
      loading: false,
      copyConfopen: false,
      copyDialogContent: '',
      copyLoading: false,
      copyRow: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      if (this.props.rows) {
        this.handleHeaderClose();
      }
    }
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = (event) => {
    const { rows } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => !row.delImposibleFlg)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const newSelected = getSelected(name, selected);
    this.setState({ selected: newSelected });
  };

  handleHeaderClose = () => {
    this.setState({ selected: [] });
  }

  onPreDelete = () => {
    this.setState({ deleteConfopen: true, loading: false });
  }

  onDelete = () => {
    const { selected } = this.state;
    this.setState({ deleteConfopen: false, selected: [], loading: true });
    this.props.onDelete(selected);
  }

  onCopyRow = () => {
    const { copyRow } = this.state;
    this.setState({ copyConfopen: false, copyRow: {}, copyLoading: true });
    this.props.onCopyRow(copyRow);
  }

  handleClose = () => {
    this.setState({ deleteConfopen: false });
  }

  handleCopyClose = () => {
    this.setState({ copyConfopen: false, copyDialogContent: '' });
  }

  getTableHeadCell(headCell) {
    const { isMain, classes } = this.props;
    const { order, orderBy } = this.state;
    if (headCell.id === 'storeName' && !isMain) {
      return null;
    }
    return (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? 'right' : 'left'}
        padding="default"
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={this.createSortHandler(headCell.id)}
          className={classes.tableLabel}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  getTableCell = (row, key, isMain) => {
    if ((key === 'storeName' && !isMain) || (key === 'companyName' && !isMain)) {
      return null;
    }
    return (
      <TableCell key={key}>{row[key]}</TableCell>
    );
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  onCopy = (row) => {
    this.setState({
      copyConfopen: true,
      copyLoading: false,
      copyRow: row,
      copyDialogContent: `${row.title}をコピーします。よろしいですか？`,
    });
  }

  render() {
    const {
      isMain,
      classes,
      rows,
      deleteButtonLabel,
      addButtonLabel,
      onAdd,
      headCells,
      onDetail,
      secondFuncText,
      onSecondFunc,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      deleteDialogTitle,
      deleteDialogContent,
      isCopy,
      copyDialogTitle,
    } = this.props;
    const {
      order, orderBy, selected, deleteConfopen, loading, copyConfopen, copyDialogContent,
      copyLoading,
    } = this.state;

    const colSpan = headCells.length + (onDetail ? 1 : 0) + 1;
    return (
      <>
        <Paper>
          <Toolbar className={selected.length > 0 ? classes.highlight : null}>
            {selected.length > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
            ) : (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
            )}

            {selected.length > 0 ? (
              <Tooltip title={deleteButtonLabel}>
                <Button color="secondary" onClick={this.onPreDelete}>{deleteButtonLabel}</Button>
              </Tooltip>
            ) : (
              <Tooltip title={addButtonLabel}>
                <Button color="primary" onClick={onAdd}>{addButtonLabel}</Button>
              </Tooltip>
            )}
          </Toolbar>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length
                        && rows.filter((row) => !row.delImposibleFlg).length !== selected.length}
                      checked={
                        rows.filter((row) => !row.delImposibleFlg).length > 0
                        && rows.filter((row) => !row.delImposibleFlg).length === selected.length
                      }
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={
                        rows.length < 1 || rows.filter((row) => !row.delImposibleFlg).length < 1
                      }
                    />
                  </TableCell>
                  {headCells.map((headCell) => this.getTableHeadCell(headCell))}
                  {onDetail && (<TableCell />)}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          {!row.delImposibleFlg && (
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              color="primary"
                              onClick={(event) => this.handleClick(event, row.id)}
                            />
                          )}
                        </TableCell>
                        {Object.keys(row)
                          .filter((value) => value !== 'id' && value !== 'delImposibleFlg' && value !== 'storeId')
                          .map((key) => this.getTableCell(row, key, isMain))}
                        <TableCell>
                          {onDetail && (
                            <Button color="primary" onClick={(event) => onDetail(event, row)}>詳細を見る</Button>
                          )}
                          {isCopy && (
                            <Button color="primary" onClick={() => this.onCopy(row)}>コピーする</Button>
                          )}
                          {secondFuncText && (
                            <Button color="primary" onClick={(event) => onSecondFunc(event, row)}>{secondFuncText}</Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={colSpan}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <ConfirmDialog
            open={deleteConfopen}
            handleClose={this.handleClose}
            executeText="削除する"
            handleExecute={this.onDelete}
            title={deleteDialogTitle}
            contentText={deleteDialogContent}
            loading={loading}
            buttonColor="secondary"
          />
          <ConfirmDialog
            open={copyConfopen}
            handleClose={this.handleCopyClose}
            executeText="コピーする"
            handleExecute={this.onCopyRow}
            title={copyDialogTitle}
            contentText={copyDialogContent}
            loading={copyLoading}
          />
        </Paper>
      </>
    );
  }
}

DeleteDataTable.propTypes = {
  isMain: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  deleteButtonLabel: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  onDetail: PropTypes.func.isRequired,
  secondFuncText: PropTypes.string,
  onSecondFunc: PropTypes.func,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  deleteDialogTitle: PropTypes.string.isRequired,
  deleteDialogContent: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

DeleteDataTable.defaultProps = {
  secondFuncText: null,
  onSecondFunc: null,
  totalElements: 0,
  isMain: null,
};

export default withStyles(styles)(DeleteDataTable);
