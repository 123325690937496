import React, { Component } from 'react';
import { format, isBefore } from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import ja from 'date-fns/locale/ja';
import { Rnd } from 'react-rnd';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Box, Tooltip, Button, Container,
} from '@material-ui/core';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import GolfSearch from './GolfSearch';
import '../../../public/css/calendar.css';
import {
  getPosition, getNewStyle, getBackground, getStyle,
  getLessonStyle,
} from './golf-schedule-proc';
import {
  actCallApiGetStores, actApiGolfScheduleFlgReset,
  actCallApiSaveGolfSchedule, actCallApiGetGolfSchedule,
} from '../../../redux/booking/golfSchedule/action';
import ErrorSnackbar from './ErrorSnackbar';
import beginner from '../../../public/assets/beginner.svg';
import DetailDrawer from './DetailDrawer';
import AddDrawer from './AddDrawer';
import urls from '../../../constants/urls';

const styles = (theme) => ({
  root: {
    padding: '24px',
    paddingTop: '60px',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    maxWidth: 220,
    fontSize: '15px',
    border: '1px solid #000',
  },
  tooltipPlacementTop: {
    top: '1000px',
  },
}))(Tooltip);

class GolfSchedule extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      { name: 'ゴルフスケジュール' },
    ];

    let store = props.common.user ? props.common.user.store : null;
    store = props.golfSchedule.info ? props.golfSchedule.info.store : store;

    const curDate = props.golfSchedule.curDate ? props.golfSchedule.curDate : format(new Date(), 'yyyy/MM/dd');
    const dispDate = format(new Date(curDate), 'yyyy年MM月dd日 (eeee)', { locale: ja });
    const isBeforeDate = isBefore(new Date(format(new Date(curDate), 'yyyy/MM/dd')), new Date(format(new Date(), 'yyyy/MM/dd')));

    this.state = {
      boxs: [],
      times: [],
      datas: [],
      lessons: [],
      selected: [],
      curDate,
      selectedData: {},
      newData: {},
      store,
      isDetailOpen: false,
      successSnackOpen: false,
      snackMessage: '',
      dispDate,
      isError: false,
      isClickDrawer: false,
      isBeforeDate,
    };
    this.props.dispatch(actCallApiGetStores());
  }

  componentDidMount() {
    document.title = 'ゴルフスケジュール';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.golfSchedule.datas !== this.props.golfSchedule.datas) {
      if (this.props.golfSchedule.datas) {
        this.setDatas(this.props.golfSchedule.datas);
      }
    }
    if (prevProps.golfSchedule.isSaveSuccess !== this.props.golfSchedule.isSaveSuccess) {
      if (this.props.golfSchedule.isSaveSuccess) {
        this.search();
      }
    }
  }

  setDatas = (data) => {
    const tempDatas = data.datas || [];
    const datas = [];
    const startTime = data.times[0];
    tempDatas.forEach((value) => {
      const a = {
        ...value,
        position: getPosition(value, startTime, data.times),
      };
      datas.push(a);
    });
    const tempLessons = data.lessons || [];
    const lessons = [];
    tempLessons.forEach((value) => {
      const a = {
        ...value,
        position: getPosition(value, startTime, data.times),
      };
      lessons.push(a);
    });
    this.setState({
      boxs: data.boxs || [],
      times: data.times || [],
      datas,
      lessons,
    });
  }

  onResearch = (store, date) => {
    this.setState({
      isDetailOpen: false,
      isNewOpen: false,
      store,
      curDate: date,
      dispDate: format(new Date(date), 'yyyy年MM月dd日 (eeee)', { locale: ja }),
      isBeforeDate: isBefore(
        new Date(format(new Date(date), 'yyyy/MM/dd')),
        new Date(format(new Date(), 'yyyy/MM/dd')),
      ),
    });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({
      successSnackOpen: false,
      isError: false,
      snackMessage: '',
    });
  }

  handleClickCell = (_, row) => {
    if (row) {
      this.setState({
        isDetailOpen: true, selectedData: row, isClickDrawer: true, isNewOpen: false,
      });
    }
  }

  handleClickLessonDetail = (_, row) => {
    const breadcrumbsObj = [
      {
        url: urls.BOOKING.GOLF_SCHEDULES,
        name: 'ゴルフスケジュール',
      },
      { name: '予約検索詳細' },
    ];

    this.props.history.push({
      pathname: urls.BOOKING.BOOKING_DETAIL,
      state: {
        bookingInfo: row,
        breadcrumbsObj,
      },
    });
  }

  handleClearDrawer = () => {
    this.setState({ isClickDrawer: false });
  }

  onDetailClose = () => {
    this.search();
    this.setState({ isDetailOpen: false, isNewOpen: false });
  }

  setMemo = (data) => {
    let retMemo = data.memo;
    if (data.memo && data.memo.length > 13) {
      retMemo = `${data.memo.substr(0, 13)}...`;
    }
    return retMemo;
  }

  onDragStop = (e, d, data) => {
    if (data.position.y === d.y && data.position.x === d.x) {
      return;
    }
    const {
      datas, boxs, times,
      store, curDate,
    } = this.state;
    const moveIndex = d.x / 100;
    let boxIndex = 0;
    let isBoxExist = false;
    boxs.forEach((value) => {
      if (value.boxNumber !== data.boxNumber && !isBoxExist) {
        boxIndex += 1;
      }
      if (value.boxNumber === data.boxNumber) {
        isBoxExist = true;
      }
    });
    const box = boxs[boxIndex + moveIndex];
    const time = times[times.length - ((((d.y + 80) / 40) * -1) + 2)];

    let isExist = false;
    datas.forEach((value) => {
      if (value.boxNumber === box.boxNumber && value.startTime === time) {
        isExist = true;
      }
    });
    this.setState({ isError: isExist });
    this.props.dispatch(actApiGolfScheduleFlgReset());
    this.props.dispatch(actCallApiSaveGolfSchedule({
      memberId: data.memberId,
      storeId: store.id,
      targetDate: curDate,
      startTime: time,
      boxNumber: box.boxNumber,
      type: data.targetType,
    }));
  }

  onClickNew = (e, startTime, boxNumber) => {
    const { isNewOpen } = this.state;
    if (isNewOpen) {
      this.setState({ isNewOpen: false });
      return;
    }
    this.setState({
      newData: { startTime, boxNumber },
      isNewOpen: true,
      isDetailOpen: false,
    });
  }

  search() {
    const { store, curDate } = this.state;
    this.props.dispatch(actApiGolfScheduleFlgReset());
    this.props.dispatch(actCallApiGetGolfSchedule(
      { searchStoreId: store.id, searchTargetDate: curDate },
    ));
  }

  render() {
    const {
      times, datas, boxs, lessons, selectedData, store,
      selected, curDate, isClickDrawer, isNewOpen, newData,
      successSnackOpen, snackMessage,
      dispDate, isError, isDetailOpen,
      isBeforeDate,
    } = this.state;
    const { classes } = this.props;
    const startTime = times[0];

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <Container
          maxWidth={false}
          className={classes.root}
        >
          <GolfSearch
            date={curDate}
            onResearch={this.onResearch}
          />
          <h2 style={{ fontWeight: 'bold' }}>{dispDate}</h2>
          <div className={(isDetailOpen || isNewOpen) ? `${classes.contentShift} rbc-time-view` : 'rbc-time-view'} style={{ overflowY: 'auto', height: '700px' }}>
            <div className="rbc-time-header rbc-overflowing">
              <div className="rbc-label rbc-time-header-gutter" style={{ width: '50px' }} />
              <div className="rbc-time-header-content">
                <div className="rbc-row rbc-time-header-cell">
                  {boxs.map((box) => (
                    <div key={box.id} className="rbc-header">{box.boxNumber}</div>
                  ))}
                </div>
              </div>
            </div>
            <div className="rbc-time-content">
              {isNewOpen && (
                <div style={getNewStyle(newData, startTime, boxs)}>
                  <Box pl={1} pt={1}>新規予約追加</Box>
                </div>
              )}
              <div className="rbc-time-gutter rbc-time-column">
                {times.map((time) => (
                  <div key={time} className="rbc-timeslot-group">
                    <div className="rbc-time-slot" style={{ width: '50px' }}><span className="rbc-label">{time}</span></div>
                  </div>
                ))}
              </div>
              {boxs.map((box) => (
                <div
                  className="rbc-day-slot rbc-time-column"
                  key={box.id}
                >
                  {times.map((time) => (
                    <Box
                      className={selected.includes(`${time}-${box.id}`) ? `time-table-${box.id} rbc-timeslot-group rbc-selected-cell` : `time-table-${box.id} rbc-timeslot-group`}
                      key={`time${time}${box.id}`}
                      data-key={`${time}-${box.id}`}
                      onClick={
                        isBeforeDate ? null : ((e) => this.onClickNew(e, time, box.boxNumber))
                      }
                    >
                      <div className="rbc-time-slot" />
                      <div className="rbc-time-slot" />
                    </Box>
                  ))}
                  <div>
                    {
                      lessons.filter(
                        (lesson) => (lesson.boxNumber === box.boxNumber),
                      ).map((lesson) => (
                        <HtmlTooltip
                          placement="left-start"
                          key={lesson.lessonId}
                          title={(
                            <div>
                              <div>{`${lesson.startTime}-${lesson.endTime}`}</div>
                              <div>{lesson.teacherName}</div>
                            </div>
                          )}
                        >
                          <Rnd
                            style={getLessonStyle(lesson)}
                            size={getLessonStyle(lesson)}
                            position={lesson.position}
                          >
                            <div>{`${lesson.startTime}-${lesson.endTime}`}</div>
                            {lesson.teacherName && (
                              <div>{`${lesson.teacherName}`}</div>
                            )}
                            <Box style={{
                              position: 'fixed', bottom: 0, right: 0, background: '#fadbda',
                            }}
                            >
                              <Button
                                onClick={(event) => this.handleClickLessonDetail(event, lesson)}
                                onTouchEnd={(event) => this.handleClickLessonDetail(event, lesson)}
                                style={{ padding: 0 }}
                              >
                                予約詳細
                              </Button>
                            </Box>
                          </Rnd>
                        </HtmlTooltip>
                      ))
                    }
                    {datas.filter(
                      (data) => (
                        data.boxNumber === box.boxNumber
                        && (
                          (data.targetType === 1 && box.box)
                          || (data.targetType === 2 && box.box)
                          || (data.targetType === 3 && !box.box)
                        )
                      ),
                    ).map((data) => (
                      <HtmlTooltip
                        placement="left-start"
                        key={data.id}
                        title={(
                          <div>
                            <Box>{`${data.startTime}-${data.endTime}`}</Box>
                            <Box mt={1}>
                              <Box fontWeight="fontWeightBold" fontSize={15}>予約メモ</Box>
                              <Box>{data.memo && data.memo.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
                            </Box>
                            <Box mt={1}>
                              <Box fontWeight="fontWeightBold" fontSize={15}>会員メモ</Box>
                              <Box>{data.memberMemo && data.memberMemo.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
                            </Box>
                            <Box mt={1}>
                              <Box fontWeight="fontWeightBold" fontSize={15}>支払</Box>
                              <Box>{data.paymentType}</Box>
                            </Box>
                            {data.phone && (
                              <Box mt={1}>
                                <Box fontWeight="fontWeightBold" fontSize={15}>電話番号</Box>
                                <Box>{data.phone}</Box>
                              </Box>
                            )}
                          </div>
                        )}
                      >
                        <Rnd
                          style={getStyle(data, curDate)}
                          size={getStyle(data, curDate)}
                          bounds=".rbc-time-content"
                          position={data.position}
                          onDragStop={(e, d) => this.onDragStop(e, d, data)}
                          dragGrid={[100, 40]}
                          disableDragging={isBeforeDate}
                        >
                          <div>
                            <div>{`${data.startTime}-${data.endTime}`}</div>
                            <Box fontWeight={data.notReserv ? 'fontWeightBold' : ''}>{data.bookingName}</Box>
                            {data.beginner && (
                              <img src={beginner} alt="初心者" style={{ width: '15px', height: 'auto' }} />
                            )}
                            <div>{this.setMemo(data)}</div>
                            <Box style={{
                              position: 'fixed', bottom: 0, right: 0, background: getBackground(data),
                            }}
                            >
                              <Button
                                onClick={(event) => this.handleClickCell(event, data)}
                                onTouchEnd={(event) => this.handleClickCell(event, data)}
                                style={{ padding: 0 }}
                              >
                                詳細
                              </Button>
                            </Box>
                          </div>
                        </Rnd>
                      </HtmlTooltip>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
        <SuccessSnackbar
          open={successSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={snackMessage}
        />
        <ErrorSnackbar
          open={isError}
          handleClose={this.handleSuccessSnackbarClose}
          message="移動できません"
        />
        <DetailDrawer
          isOpen={isDetailOpen}
          row={selectedData}
          onClose={this.onDetailClose}
          isClickDrawer={isClickDrawer}
          handleClearDrawer={this.handleClearDrawer}
          isBeforeDate={isBeforeDate}
        />
        <AddDrawer
          isOpen={isNewOpen}
          onClose={this.onDetailClose}
          targetStore={store}
          targetDate={curDate}
          newData={newData}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, golfSchedule: state.golfSchedule });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(GolfSchedule)));
