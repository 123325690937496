const memberNameValidation = (memberName) => {
  if (!memberName) return '入力してください';
  return '';
};

const memberPhoneValidation = (phone) => {
  if (!phone) return '入力してください';
  const regex = /^([0-9-]*)$/;
  if (!regex.test(phone)) return '数値と-のみを入力してください';
  return '';
};

const memberValidation = (member) => {
  if (!member || !member.id) return '選択してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'memberName':
        return memberNameValidation(value);
      case 'memberPhone':
        return memberPhoneValidation(value);
      case 'member':
        return memberValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
