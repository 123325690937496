import { ACTION_TYPE } from './action';

const members = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.INIT_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.CALL_API_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CHANGE_MEMBER_PASSWORD_SUCCESS:
      return {
        ...state,
        updateData: action.datas,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        isDeleteMemberSuccess: true,
      };
    case ACTION_TYPE.PASSWORD_RESET_FLG:
      return {
        ...state,
        isUpdateSuccess: false,
        isDeleteMemberSuccess: false,
      };
    case ACTION_TYPE.CALL_API_GET_CSV_MEMBER_DATA_SUCCESS:
      return {
        ...state,
        csvDatas: action.datas,
      };
    case ACTION_TYPE.SET_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    default:
      return state;
  }
};

export default members;
