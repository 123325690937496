import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles, Drawer, IconButton, Divider, Box, Button, LinearProgress, Tooltip,
  FormControl, RadioGroup, FormControlLabel, Radio, TextField, FormHelperText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiGetGolfScheduleAdd, actApiGolfScheduleFlgReset, actCallApiGolfScheduleAdd,
} from '../../../redux/booking/golfSchedule/action';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import RoomMemberAddDialog from '../roomSchedule/roomScheduleDialog/RoomMemberAddDialog';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import RequiredChip from '../../../components/atoms/requiredChip/RequiredChip';
import Validation from './validation';

const styles = (theme) => ({
  drawer: {
    width: 500,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 500,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
});

const defaultErrorMessage = {
  member: '',
  memberName: '',
  memberPhone: '',
};

class AddDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      form: {
        memberName: '',
        memberPhone: '',
        member: {},
      },
      errorMessage: defaultErrorMessage,
      isSuccess: false,
      isMemberSearchOpen: false,
      memberType: '会員',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.init();
      }
    }
    if (prevProps.golfSchedule.newData !== this.props.golfSchedule.newData) {
      if (this.props.golfSchedule.newData) {
        this.setData(this.props.golfSchedule.newData);
      }
    }
    if (prevProps.golfSchedule.isAddSuccess !== this.props.golfSchedule.isAddSuccess) {
      if (this.props.golfSchedule.isAddSuccess) {
        this.success();
      }
    }
  }

  onChange = (event) => {
    const { value, name } = event.target;
    const { form, errorMessage } = this.state;

    this.setState({
      form: { ...form, [name]: value },
      errorMessage: { ...errorMessage, [name]: Validation.formValidate(name, value) },
    });
  }

  onSave = () => {
    const {
      data, form, errorMessage, memberType,
    } = this.state;

    let isError = false;
    let tmpErrorMessage = errorMessage;
    Object.keys(form).map((name) => {
      if (
        (memberType === '会員' && name !== 'member')
        || (memberType === 'ビジター' && (name !== 'memberPhone' && name !== 'memberName'))
      ) {
        return null;
      }
      const message = Validation.formValidate(name, form[name]);
      if (message) {
        isError = true;
      }
      tmpErrorMessage = {
        ...tmpErrorMessage,
        [name]: message,
      };
      return message;
    });
    this.setState({
      errorMessage: tmpErrorMessage,
    });
    if (isError) {
      return;
    }

    this.props.dispatch(actApiGolfScheduleFlgReset());
    this.props.dispatch(actCallApiGolfScheduleAdd({
      bookingId: data.bookingId,
      targetType: data.targetType,
      memberId: form.member && form.member.id,
      memberName: form.memberName,
      memberPhone: form.memberPhone,
      paymentType: form.paymentType,
      boxNumber: data.boxNumber,
    }));
  }

  onMemberSearchClose = () => {
    this.setState({ isMemberSearchOpen: false });
  }

  onMemberSearchOpen = () => {
    this.setState({ isMemberSearchOpen: true });
  }

  handleClickSelectUser = (row) => {
    const { form, errorMessage } = this.state;
    this.setState({
      form: {
        ...form,
        member: row,
      },
      isMemberSearchOpen: false,
      errorMessage: {
        ...errorMessage,
        member: Validation.formValidate('member', row),
      },
    });
  }

  onClickMemberDelete = () => {
    const { form, errorMessage } = this.state;
    this.setState({
      form: {
        ...form,
        member: null,
      },
      errorMessage: {
        ...errorMessage,
        member: Validation.formValidate('member', null),
      },
    });
  }

  onMemberChange = (e) => {
    const { form } = this.state;
    this.setState({
      memberType: e.target.value,
      form: {
        ...form,
        memberName: '',
        member: {},
        memberPhone: '',
      },
      errorMessage: defaultErrorMessage,
    });
  }

  onSnackbarClose = () => {
    this.setState({ isSuccess: false });
  }

  setData(data) {
    this.setState({ data });
  }

  success() {
    this.setState({ isSuccess: true });
    this.props.onClose();
  }

  init() {
    const {
      newData, targetStore, targetDate,
    } = this.props;
    this.props.dispatch(actApiGolfScheduleFlgReset());
    this.props.dispatch(actCallApiGetGolfScheduleAdd({
      storeId: targetStore.id,
      targetDate,
      startTime: newData.startTime,
      boxNumber: newData.boxNumber,
    }));
    this.setState({
      form: {
        memberName: '',
        memberPhone: '',
        member: {},
      },
      errorMessage: defaultErrorMessage,
    });
  }

  render() {
    const {
      classes, isOpen, onClose, isLoading, targetStore,
    } = this.props;
    const {
      data, isSuccess, isMemberSearchOpen, memberType, form, errorMessage,
    } = this.state;

    return (
      <>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={isOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Box display="flex" fontSize={17}>
              <Box>{data.errorMessage ? 'エラー' : '予約登録'}</Box>
            </Box>
          </div>
          <Divider />
          {isLoading && (<LinearProgress color="primary" />)}
          {!data.errorMessage && (
            <Box>
              <Box p={2} fontSize={17}>
                <Box mb={2}>
                  <FormTitle title="実施日" />
                  <Box>{data.targetedOn}</Box>
                </Box>
                <Box mb={2}>
                  <FormTitle title="実施時間" />
                  <Box>{`${data.startTime}～${data.endTime}`}</Box>
                </Box>
                <Box mb={2}>
                  <FormTitle title="ステータス" />
                  <Box>{data.statusName}</Box>
                </Box>
              </Box>
              <Divider />
              <Box p={2}>
                <Box mb={2}>
                  <FormControl component="fieldset">
                    <RadioGroup row value={memberType} onChange={this.onMemberChange} defaultValue="会員" name="memberType">
                      <FormControlLabel value="会員" control={<Radio color="primary" />} label="会員" />
                      <FormControlLabel value="ビジター" control={<Radio color="primary" />} label="ビジター" />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box mb={2}>
                  {memberType === '会員' && (
                    <Box>
                      <Box fontWeight="fontWeightBold" mb={1} fontSize={15}>
                        会員
                        <RequiredChip />
                      </Box>
                      {form.member && form.member.id && (
                        <Box display="flex" mt={1.5}>
                          <Box>
                            <Box>
                              {form.member.memberNo}
                            </Box>
                            <Box>
                              {form.member.name}
                            </Box>
                          </Box>
                          <Box>
                            <Tooltip title="削除">
                              <IconButton aria-label="delete" onClick={this.onClickMemberDelete}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                      <Box>
                        <Button color="primary" size="small" onClick={this.onMemberSearchOpen}>
                          会員を検索する
                        </Button>
                        <FormErrorText>{errorMessage.member}</FormErrorText>
                      </Box>
                    </Box>
                  )}
                  {memberType === 'ビジター' && (
                    <Box>
                      <Box mb={2}>
                        <Box fontWeight="fontWeightBold" mb={1} fontSize={15}>
                          名前
                          <RequiredChip />
                        </Box>
                        <TextField
                          name="memberName"
                          size="small"
                          variant="outlined"
                          onChange={this.onChange}
                          autoComplete="off"
                          value={form.memberName}
                          error={errorMessage.memberName !== ''}
                          inputProps={{ maxLength: 100 }}
                          fullWidth
                        />
                        <FormErrorText>{errorMessage.memberName}</FormErrorText>
                      </Box>
                      <Box mb={2}>
                        <Box fontWeight="fontWeightBold" mb={1} fontSize={15}>
                          電話番号
                          <RequiredChip />
                        </Box>
                        <TextField
                          name="memberPhone"
                          size="small"
                          variant="outlined"
                          onChange={this.onChange}
                          autoComplete="off"
                          value={form.memberPhone}
                          error={errorMessage.memberPhone !== ''}
                          inputProps={{ maxLength: 20 }}
                          fullWidth
                        />
                        <FormErrorText>{errorMessage.memberPhone}</FormErrorText>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box mb={2}>
                  <FormTitle title="支払方法" />
                  <FormControl component="fieldset">
                    <RadioGroup row value={form.paymentType} onChange={this.onChange} defaultValue={form.paymentType} name="paymentType">
                      <FormControlLabel value="" control={<Radio checked={form.paymentType === ''} color="primary" />} label="なし" />
                      <FormControlLabel value="現金" control={<Radio checked={form.paymentType === '現金'} color="primary" />} label="現金" />
                      <FormControlLabel value="チケット" control={<Radio checked={form.paymentType === 'チケット'} color="primary" />} label="チケット" />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <FormTitle title="メモ" />
                  <Box>
                    <TextField
                      id="content"
                      name="memo"
                      multiline
                      fullWidth
                      rows={5}
                      variant="outlined"
                      value={form.memo}
                      onChange={this.onChange}
                      inputProps={{
                        maxLength: 30000,
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Button color="primary" onClick={this.onSave} variant="contained" size="small">追加</Button>
                </Box>
              </Box>

            </Box>
          )}
          {data.errorMessage && (
            <Box p={2}>
              <FormHelperText error style={{ fontSize: 17 }}>{data.errorMessage}</FormHelperText>
            </Box>
          )}
        </Drawer>
        <RoomMemberAddDialog
          open={isMemberSearchOpen}
          onClose={this.onMemberSearchClose}
          targetStore={targetStore}
          handleClickSelectUser={this.handleClickSelectUser}
        />
        <SuccessSnackbar
          open={isSuccess}
          handleClose={this.onSnackbarClose}
          message="追加が完了しました"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  golfSchedule: state.golfSchedule,
  isLoading: state.common.isLoading,
});

export default withStyles(styles)(connect(mapStateToProps)(AddDrawer));
