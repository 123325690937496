import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table, TableBody,
  TableCell, TableContainer,
  TableHead, TableSortLabel,
  TableRow, Button, withStyles,
  Paper, Toolbar, Typography, Tooltip,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import urls from '../../../constants/urls';
import Pagination from '../../../components/atoms/pagination/Pagination';
import { getComparator, stableSort } from '../../../helpers/tableSort.helper';
import MemberAddDialog from './MemberAddDialog';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import { actCallDeleteMember, actResetMemberUpdateFlg } from '../../../redux/member/memberList/action';

const styles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  tableLabel: {
    verticalAlign: 'baseline',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  downloadRoot: {
    marginRight: '10px',
  },
});

class MemberListTable extends React.Component {
  constructor() {
    super();
    this.headCells = [
      { id: 'memberNo', numeric: false, label: '会員番号' },
      { id: 'storeName', numeric: false, label: '店舗' },
      { id: 'name', numeric: false, label: '名前' },
      { id: 'nameKana', numeric: false, label: '名前フリガナ' },
    ];
    this.state = {
      order: 'asc',
      orderBy: '',
      isAddOpen: false,
      selected: [],
      isDeleteOpen: false,
      loading: false,
    };
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;

    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  onAdd = () => {
    const { user } = this.props.common;
    const companyId = user ? user.store.mstOrganizationCompanyId : 1;

    if (companyId === 4) {
      this.props.history.push({ pathname: urls.MEMBER.INDIVIDUAL_ENTRY });
      return;
    }
    this.setState({ isAddOpen: true });
  }

  onAddClose = () => {
    this.setState({ isAddOpen: false });
  }

  onDelete = () => {
    this.setState({ isDeleteOpen: true });
  }

  onDeleteClose = () => {
    this.setState({ isDeleteOpen: false });
  }

  onDeleteData = () => {
    const { selected: deleteMemberIds } = this.state;
    this.props.dispatch(actResetMemberUpdateFlg());
    this.props.dispatch(actCallDeleteMember({
      deleteList: deleteMemberIds,
    }));
    this.setState({ selected: [], isDeleteOpen: false });
  }

  getTableHeadCell = (headCell) => {
    const { isMain, classes } = this.props;
    const { order, orderBy } = this.state;
    if (headCell.id === 'storeName' && !isMain) {
      return null;
    }
    return (
      <TableCell
        key={headCell.id}
        align="left"
        padding="default"
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={this.createSortHandler(headCell.id)}
          className={classes.tableLabel}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  handleHeaderClose = () => {
    this.setState({ selected: [] });
  }

  isSelected = (rowId) => this.state.selected.includes(rowId);

  handleClick = (_, rowId) => {
    const { selected: rowIds } = this.state;
    const selectedIndex = rowIds.indexOf(rowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(rowIds, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(rowIds.slice(1));
    } else if (selectedIndex === rowIds.length - 1) {
      newSelected = newSelected.concat(rowIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        rowIds.slice(0, selectedIndex),
        rowIds.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleSelectAllClick = () => {
    const { selected: rowIds, order, orderBy } = this.state;
    const { rows } = this.props;
    const tableDatas = stableSort(rows, getComparator(order, orderBy));
    let selectedData = [];
    if (rowIds.length !== tableDatas.length) {
      const newSelecteds = tableDatas.map((scr) => scr.id);
      selectedData = newSelecteds;
    }

    this.setState({ selected: selectedData });
  };

  render() {
    const {
      classes,
      isMain,
      rows,
      firstFuncText,
      onFirstFunc,
      secondFuncText,
      onSecondFunc,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      onDownload,
    } = this.props;
    const {
      order, orderBy, isAddOpen, selected, isDeleteOpen, loading,
    } = this.state;

    return (
      <>
        <Paper>
          <Toolbar className={selected.length > 0 ? classes.highlight : ''}>
            {selected.length > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
            ) : (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
            )}
            {onDownload && (
              <Tooltip title="CSVの出力">
                <Button color="primary" onClick={onDownload} className={classes.downloadRoot}>CSVの出力</Button>
              </Tooltip>
            )}
            <Tooltip title="会員の追加">
              <Button color="primary" onClick={this.onAdd}>会員の追加</Button>
            </Tooltip>
            {selected.length > 0 && (
            <Tooltip title="会員の削除">
              <Button color="secondary" onClick={this.onDelete}>会員の削除</Button>
            </Tooltip>
            )}
          </Toolbar>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" padding="checkbox">
                    {' '}
                    <Checkbox
                      onClick={this.handleSelectAllClick}
                      checked={
                        selected.length > 0
                        && selected.length
                          === stableSort(rows, getComparator(order, orderBy)).length
                      }
                    />
                  </TableCell>
                  {this.headCells.map((headCell) => this.getTableHeadCell(headCell))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .map((row) => {
                    const isSelected = this.isSelected(row.id);
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell role="checkbox">
                          <Checkbox
                            checked={isSelected}
                            inputProps={{ 'aria-labelledby': row.id }}
                            color="primary"
                            onClick={(event) => this.handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell>
                          {row.memberNo}
                        </TableCell>
                        <TableCell style={{ display: isMain ? '' : 'none' }}>
                          {row.storeName}
                        </TableCell>
                        <TableCell>
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.nameKana}
                        </TableCell>
                        <TableCell>
                          {firstFuncText && <Button color="primary" onClick={(event) => onFirstFunc(event, row)}>{firstFuncText}</Button>}
                          {secondFuncText && <Button color="primary" onClick={(event) => onSecondFunc(event, row)}>{secondFuncText}</Button>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={5}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <MemberAddDialog open={isAddOpen} handleClose={this.onAddClose} />
        <ConfirmDialog
          open={isDeleteOpen}
          handleClose={this.onDeleteClose}
          executeText="削除する"
          handleExecute={this.onDeleteData}
          title="会員の削除"
          contentText="選択した予約者を削除します。この操作は元に戻すことができません。"
          loading={loading}
          buttonColor="secondary"
        />
      </>
    );
  }
}

MemberListTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  firstFuncText: PropTypes.string,
  onFirstFunc: PropTypes.func,
  secondFuncText: PropTypes.string,
  onSecondFunc: PropTypes.func,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

MemberListTable.defaultProps = {
  secondFuncText: null,
  firstFuncText: null,
  onFirstFunc: null,
  onSecondFunc: null,
  totalElements: 0,
};

const mapStateToProps = (state) => ({ common: state.common });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MemberListTable)));
