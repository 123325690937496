import { REST_API } from '../../constants/restApiUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 検索条件から会員情報を取得する
 *
 * @param {object} searchCondition
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MEMBER.MEMBER_LIST.MEMBER_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員パスワードを更新する
 * @param {*} password
 */
export function setMemberPasswordUpdate(id, password) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .put(REST_API.MEMBER.MEMBER_LIST.MEMBER_PASSWORD, { id }, password)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * CSV出力用データ取得
 * @param {*} param
 * @returns
 */
export function getCsvDatas(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.MEMBER.MEMBER_LIST.MEMBERS_CSV, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function deleteMember(memberIds) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.MEMBER.MEMBER_LIST.MEMBER_LIST, memberIds)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
