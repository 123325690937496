import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Box, TextField } from '@material-ui/core';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import MemberListTable from './MemberListTable';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallInitMemberList, actCallApiMemberList, setSearchCondition,
  actResetMemberUpdateFlg,
} from '../../../redux/member/memberList/action';
import MemberDownloadModal from './MemberDownloadModal';
import urls from '../../../constants/urls';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

class MemberList extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(actCallInitMemberList());

    this.breadcrumbsText = [
      { name: '会員一覧' },
    ];

    let isMain = false;
    let isShieijyuku = false;
    if (props.common.user) {
      isMain = props.common.user.main;
      isShieijyuku = props.common.user.store.mstOrganizationCompanyId === 4;
      this.props.dispatch(
        actCallApiMemberList({
          page: 0,
          records: 10,
        }),
      );
    }

    this.state = {
      isMain,
      stores: [],
      datas: {},
      searchStore: null,
      searchMemberNo: '',
      searchMemberName: '',
      searchMemberNameKana: '',
      page: 0,
      records: 10,
      isDonwloadOpen: false,
      searchCondition: {},
      isShieijyuku,
      deleteSuccessSnackOpen: false,
      snackMessage: '',
    };
  }

  componentDidMount() {
    document.title = '会員一覧';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.onStoreChangeSearch(this.props.common.user);
      }
    }
    if (prevProps.members.datas !== this.props.members.datas) {
      if (this.props.members.datas) {
        this.setDatas(this.props.members.datas);
      }
    }
    if (prevProps.members.stores !== this.props.members.stores) {
      if (this.props.members.stores) {
        this.setStores(this.props.members.stores);
      }
    }
    if (prevProps.members.isDeleteMemberSuccess !== this.props.members.isDeleteMemberSuccess) {
      if (this.props.members.isDeleteMemberSuccess) {
        this.onDeleteSuccess('会員の削除が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setStores(stores) {
    this.setState({ stores });
  }

  onStoreChangeSearch = (user) => {
    this.setState({ isMain: user.main });

    this.props.dispatch(
      actCallApiMemberList({
        page: 0,
        records: 10,
      }),
    );
  }

  onStoreChange = (event) => {
    this.setState({ searchStore: event.target.value });
  }

  onMemberNoChange = (event) => {
    this.setState({ searchMemberNo: event.target.value });
  }

  onMemberNameChange = (event) => {
    this.setState({ searchMemberName: event.target.value });
  }

  onMemberNameKanaChange = (event) => {
    this.setState({ searchMemberNameKana: event.target.value });
  }

  onSearch = () => {
    const {
      searchStore, searchMemberNo, searchMemberName, searchMemberNameKana, records,
    } = this.state;
    const {
      store,
    } = this.props.common.user;
    const page = 0;
    this.setState({
      page,
      searchCondition: {
        searchStore,
        searchMemberNo,
        searchMemberName,
        searchMemberNameKana,
      },
      isShieijyuku: ((searchStore !== null
        && searchStore.mstOrganizationCompanyId === 4)
        || store.mstOrganizationCompanyId === 4),
    });
    this.search(page, records, searchStore, searchMemberNo, searchMemberName, searchMemberNameKana);
  }

  search = (page, records, searchStore, searchMemberNo, searchMemberName, searchMemberNameKana) => {
    this.props.dispatch(actResetMemberUpdateFlg());
    this.props.dispatch(
      actCallApiMemberList({
        page,
        records,
        searchStoreId:
        searchStore && searchStore.id,
        searchMemberNo,
        searchMemberName,
        searchMemberNameKana,
      }),
    );
  }

  onDeleteSuccess = (message) => {
    this.setState({
      deleteSuccessSnackOpen: true,
      snackMessage: message,
    });
    this.onSearch();
  }

  handleChangePage = (_, newPage) => {
    const {
      searchStore, searchMemberNo, searchMemberName, searchMemberNameKana, records,
    } = this.state;
    this.setState({ page: newPage });
    this.search(
      newPage, records, searchStore,
      searchMemberNo, searchMemberName, searchMemberNameKana,
    );
  };

  handleChangeRowsPerPage = (event) => {
    const {
      searchStore, searchMemberNo, searchMemberName, searchMemberNameKana,
    } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.setState({ page: 0, records: rowsPerPage });
    this.search(
      0, rowsPerPage, searchStore,
      searchMemberNo, searchMemberName, searchMemberNameKana,
    );
  };

  onDetail = (_, row) => {
    const {
      searchStore, searchMemberNo, searchMemberName, searchMemberNameKana, page, records,
    } = this.state;
    const memberId = row.id;
    this.props.dispatch(setSearchCondition({
      searchCondition: {
        searchStore,
        searchMemberNo,
        searchMemberName,
        searchMemberNameKana,
        page,
        records,
      },
    }));
    this.props.history.push({
      pathname: urls.MEMBER.MEMBER_DETAIL,
      state: {
        memberId,
      },
    });
  }

  onClickDownloadOpen = () => {
    this.setState({ isDonwloadOpen: true });
  }

  onClickDownloadClose = () => {
    this.setState({ isDonwloadOpen: false });
  }

  onDelete = (message) => {
    this.onDeleteSuccess(message);
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ deleteSuccessSnackOpen: false });
  }

  render() {
    const {
      stores,
      datas,
      isMain,
      searchStore,
      searchMemberNo,
      searchMemberName,
      searchMemberNameKana,
      page,
      records,
      isDonwloadOpen,
      searchCondition,
      isShieijyuku,
      deleteSuccessSnackOpen,
      snackMessage,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch}>
            <Box display="flex">
              <Box display={isMain ? '' : 'none'} width="30%" mr={2}>
                <FormTitle title="店舗" />
                <SearchSelectBox options={stores} value={searchStore} onChange={this.onStoreChange} name="searchStore" />
              </Box>
              <Box width="30%">
                <FormTitle title="会員番号" />
                <TextField id="search-member-no" variant="outlined" fullWidth value={searchMemberNo} onChange={this.onMemberNoChange} />
              </Box>
            </Box>

            <Box display="flex" mt={2}>
              <Box width="30%" mr={2}>
                <FormTitle title="名前" />
                <TextField id="search-member-name" variant="outlined" fullWidth value={searchMemberName} onChange={this.onMemberNameChange} />
              </Box>

              <Box width="30%">
                <FormTitle title="名前フリガナ" />
                <TextField id="search-member-name-kana" variant="outlined" fullWidth value={searchMemberNameKana} onChange={this.onMemberNameKanaChange} />
              </Box>
            </Box>
          </SearchBox>

          <MemberListTable
            rows={datas.content ? datas.content : []}
            page={page}
            isMain={isMain}
            rowsPerPage={records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            firstFuncText="詳細を開く"
            onFirstFunc={this.onDetail}
            onDownload={isShieijyuku ? this.onClickDownloadOpen : null}
            onDelete={this.onDelete}
          />
        </MainBody>

        <MemberDownloadModal
          open={isDonwloadOpen}
          onClose={this.onClickDownloadClose}
          searchCondition={searchCondition}
        />

        <SuccessSnackbar
          open={deleteSuccessSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={snackMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ members: state.members, common: state.common });

export default withRouter(connect(mapStateToProps)(MemberList));
