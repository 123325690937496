import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box,
  TextField,
} from '@material-ui/core';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import MainBody from '../../../components/templates/mainBody/MainBody';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import {
  actCallApiInitNoticeList,
  actCallApiNoticeListSearch,
  actCallApiDeleteNotice,
  actResetNoticeFlg,
  actCallApiCopyNotice,
} from '../../../redux/master/notice/action';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import urls from '../../../constants/urls';

class NoticeList extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbsText = [
      { name: 'お知らせ一覧' },
    ];

    let isMain = false;
    if (props.common.user) {
      isMain = props.common.user.main;
    }

    this.headCells = [
      { id: 'title', numeric: false, label: 'タイトル' },
      { id: 'category', numeric: false, label: 'カテゴリ' },
      { id: 'startDate', numeric: false, label: '開始日' },
      { id: 'endDate', numeric: false, label: '終了日' },
      { id: 'storeName', numeric: false, label: '加盟企業' },
    ];

    const searchCondition = {
      page: 0,
      records: 10,
      searchCompany: null,
      searchTitle: '',
      searchCategory: null,
      searchStartDate: null,
      searchEndDate: null,
      changeLoading: false,
    };

    this.state = {
      isMain,
      select: {
        companies: [],
        categories: [],
      },
      datas: {},
      searchCondition,
      isSuccessSnackOpen: false,
      successMessage: '',
    };

    this.search(searchCondition);
    props.dispatch(actCallApiInitNoticeList());
  }

  componentDidMount() {
    document.title = 'お知らせ一覧';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.setUser(this.props.common.user);
      }
    }
    if (prevProps.notices.datas !== this.props.notices.datas) {
      if (this.props.notices.datas) {
        this.setDatas(this.props.notices.datas);
      }
    }
    if (prevProps.notices.select !== this.props.notices.select) {
      if (this.props.notices.select) {
        this.setSelect(this.props.notices.select);
      }
    }
    if (prevProps.notices.isDeleteSuccess !== this.props.notices.isDeleteSuccess) {
      if (this.props.notices.isDeleteSuccess) {
        this.setSuccess('削除');
      }
    }
    if (prevProps.notices.copyNoticeId !== this.props.notices.copyNoticeId) {
      if (this.props.notices.copyNoticeId) {
        this.copyNoticeSuccess(this.props.notices.copyNoticeId);
      }
    }
  }

  setSuccess(title) {
    this.setState({
      successMessage: `${title}が完了しました`,
      isSuccessSnackOpen: true,
    });
    const { searchCondition } = this.state;
    this.search(searchCondition);
    this.props.dispatch(actCallApiInitNoticeList());
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSelect(select) {
    this.setState({ select });
  }

  setUser = (user) => {
    this.setState({
      isMain: user.main,
    });
  }

  onChange = (event) => {
    const { searchCondition } = this.state;
    const { name, value } = event.target;
    this.setState({ searchCondition: { ...searchCondition, [name]: value } });
  }

  onDateChange = (value, name) => {
    const { searchCondition } = this.state;
    const tempSearchCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempSearchCondition });
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  onAdd = () => {
    this.props.history.push({
      pathname: urls.MASTER.NOTICE_DETAIL,
      state: {
        id: null,
      },
    });
  }

  onNoticeDetailOpen = (_, row) => {
    this.props.history.push({
      pathname: urls.MASTER.NOTICE_DETAIL,
      state: {
        id: row.id,
      },
    });
  }

  search = (searchCondition) => {
    this.props.dispatch(
      actCallApiNoticeListSearch({
        ...searchCondition,
        searchCompanyId: searchCondition.searchCompany && searchCondition.searchCompany.id,
        searchTitle: searchCondition.searchTitle,
        searchCategory: searchCondition.searchCategory
          ? searchCondition.searchCategory.name : '',
        searchStartDate: searchCondition.searchStartDate,
        searchEndDate: searchCondition.searchEndDate,
      }),
    );
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (deleteList) => {
    this.props.dispatch(actResetNoticeFlg());
    this.props.dispatch(
      actCallApiDeleteNotice({ deleteList }),
    );
  }

  copyNotice = (notice) => {
    this.props.dispatch(
      actCallApiCopyNotice({ id: notice.id }),
    );
  }

  onSuccessSnackbarClose = () => {
    this.setState({ isSuccessSnackOpen: false });
  }

  copyNoticeSuccess = (copyNoticeId) => {
    this.props.history.push({
      pathname: urls.MASTER.NOTICE_DETAIL,
      state: {
        id: copyNoticeId,
      },
    });
  }

  render() {
    const {
      select,
      datas,
      isMain,
      searchCondition,
      isSuccessSnackOpen,
      successMessage,
    } = this.state;

    return (
      <>
        <BasicBreadcrumbs breadcrumbs={this.breadcrumbsText} />
        <MainBody>
          <SearchBox onSearch={this.onSearch}>
            <Box display="flex">
              <Box display={isMain ? '' : 'none'} width="30%" mr={2}>
                <FormTitle title="加盟企業" />
                <SearchSelectBox options={select.companies} value={searchCondition.searchCompany} onChange={this.onChange} name="searchCompany" />
              </Box>
              <Box width="30%">
                <FormTitle title="タイトル" />
                <TextField name="searchTitle" variant="outlined" fullWidth value={searchCondition.searchTeacherName} onChange={this.onChange} />
              </Box>
            </Box>
            <Box display="flex" mt={2}>
              <Box width="45%" mr={2}>
                <FormTitle title="カテゴリ" />
                <SearchSelectBox options={select.categories} value={searchCondition.searchCategory} onChange={this.onChange} name="searchCategory" />
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="公開日" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <Datepicker value={searchCondition.searchStartDate} handleChange={(_, value) => this.onDateChange(value, 'searchStartDate')} />
                </Box>
                <Box mr={4} ml={4}>～</Box>
                <Box>
                  <Datepicker value={searchCondition.searchEndDate} handleChange={(dateValue, value) => this.onDateChange(value, 'searchEndDate')} />
                </Box>
              </Box>
            </Box>
          </SearchBox>

          <DeleteDataTable
            rows={datas.content ? datas.content : []}
            headCells={this.headCells}
            addButtonLabel="お知らせの追加"
            deleteButtonLabel="お知らせの削除"
            page={searchCondition.page}
            rowsPerPage={searchCondition.records}
            totalElements={datas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onDelete={this.onDelete}
            onAdd={this.onAdd}
            deleteDialogTitle="お知らせの削除"
            deleteDialogContent="選択したお知らせを削除します。この操作は元に戻すことができません。"
            onDetail={this.onNoticeDetailOpen}
            isMain={isMain}
            isCopy
            copyDialogTitle="お知らせのコピー"
            onCopyRow={this.copyNotice}
          />

          <SuccessSnackbar
            open={isSuccessSnackOpen}
            handleClose={this.onSuccessSnackbarClose}
            message={successMessage}
          />

        </MainBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ notices: state.notices, common: state.common });

export default withRouter(connect(mapStateToProps)(NoticeList));
