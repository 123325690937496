import { COMMON } from './common';

export const REST_URL = COMMON.ROOT_URL;
export const REST_COMMON_URL = COMMON.COMMON_URL;

export const REST_API = {
  USER: {
    LOGIN: `${REST_URL}/authenticate`,
    ME: `${REST_URL}/me`,
  },

  OTHER: {
    HOME: {
      HOME: `${REST_URL}/booking-counts`,
    },
    PASSWORD: {
      PASSWORD: `${REST_URL}/change-password/{id}`,
    },
  },

  COMMON: {
    STORES: `${REST_URL}/stores`,
    ZIPCODEADDRESS: `${REST_COMMON_URL}/zipcode`,
  },

  SHARE: {
    SELECT_LIST: `${REST_URL}/select-lists`,
  },

  BOOKING: {
    BOOKING_LIST: {
      BOOKING_LIST: `${REST_URL}/bookings`,
      BOOKING_SELECT: `${REST_URL}/bookings/selects`,
    },
    SUBSCRIBER_LIST: {
      SUBSCRIBER_LIST: `${REST_URL}/subscribers`,
      SUBSCRIBER_DETAIL: `${REST_URL}/subscribers/detail`,
      SUBSCRIBERS: `${REST_URL}/subscribers/{id}`,
      SUBSCRIBER_CANCEL: `${REST_URL}/subscribers/cancel/{id}`,
      GET_STATUS: `${REST_URL}/subscribers/status`,
    },
    BOOKING_DETAIL: {
      BOOKING_DETAIL: `${REST_URL}/bookings/detail`,
      BOOKING_DETAIL_MEMBERS: `${REST_URL}/bookings/detail/members`,
      BOOKING_DETAIL_FIND_MEMBERS: `${REST_URL}/bookings/detail/find-members`,
      BOOKING_DETAIL_EXPERIENCE: `${REST_URL}/bookings/detail/experience`,
      BOOKING_DETAIL_UPDATE: `${REST_URL}/bookings/detail/update/{id}`,
      BOOKING_DETAIL_CANCEL: `${REST_URL}/bookings/detail/cancel/{id}`,
      RESEND_ZOOMURL_TEACHER: `${REST_URL}/bookings/detail/resend-zoomurl-teachers`,
      CREATE_ZOOM_WEBINARS: `${REST_URL}/bookings/detail/create-webinars`,
      BOOKING_MEMO_UPDATE: `${REST_URL}/bookings/detail/update-memos/{id}`,
      GOLF_BOXS: `${REST_URL}/bookings/detail/golf-boxs`,
      GOLF_BOX_UPDATE: `${REST_URL}/bookings/detail/golf-boxs/update`,
    },
    ROOM_SCHEDULE: {
      ROOM_SCHEDULE: `${REST_URL}/room-schedules`,
      ROOM_SCHEDULE_FIND_MEMBERS: `${REST_URL}/room-schedules/find-members`,
      ROOM_SCHEDULE_INSERT: `${REST_URL}/room-schedules/insert`,
      ROOM_SCHEDULE_UPDATE: `${REST_URL}/room-schedules/update/{id}`,
      ROOM_SCHEDULE_FIND_SCHEDULE: `${REST_URL}/room-schedules/find-schedule`,
      ROOM_SCHEDULE_DELETE: `${REST_URL}/room-schedules/delete/{id}`,
      ROOM_SCHEDULE_DETAIL_ID: `${REST_URL}/room-schedules/detail/{id}`,
      ROOM_SCHEDULE_UPDATE_MEMBER: `${REST_URL}/room-schedules/detail`,
      ROOM_SCHEDULE_SELECT: `${REST_URL}/room-schedules/select`,
    },
    GOLF_SCHEDULE: {
      GOLF_SCHEDULE_SELECT: `${REST_URL}/golf-schedules/select`,
      GOLF_SCHEDULE: `${REST_URL}/golf-schedules`,
      GOLF_SCHEDULE_ID: `${REST_URL}/golf-schedules/{id}`,
      GOLF_SCHEDULE_ADD: `${REST_URL}/golf-schedules/add`,
    },
  },

  EVENT: {
    EVENT_WEEK_SCHEDULE: {
      EVENT_WEEK_SCHEDULE_LIST: `${REST_URL}/event-schedules`,
      EVENT_WEEK_SCHEDULE_LIST_INFO: `${REST_URL}/event-schedules/{id}`,
      EVENT_WEEK_SCHEDULE_LIST_COPY: `${REST_URL}/event-schedules/copy`,
      EVENT_WEEK_SCHEDULE_LIST_SELECTS: `${REST_URL}/event-schedules/selects`,
      EVENT_WEEK_SCHEDULE_DETAIL: `${REST_URL}/event-schedule-details`,
      EVENT_WEEK_SCHEDULE_DETAIL_INFO: `${REST_URL}/event-schedule-details/{id}`,
    },
    EVENT_CALENDAR: {
      CALENDAR_SELECT_LIST: `${REST_URL}/calendar-selects`,
      EVENT_CALENDAR_LIST: `${REST_URL}/event-calendars`,
      EVENT_CALENDAR_LIST_GOLF: `${REST_URL}/event-calendars/golf`,
      EVENT_CALENDAR_LIST_ROOM: `${REST_URL}/event-calendars/room`,
      EVENT_CALENDAR_LIST_ONLINE_CULTURE: `${REST_URL}/event-calendars/online-cultures`,
      EVENT_CALENDAR_LIST_WEEK_SCHEDULES: `${REST_URL}/event-calendars/week-schedules`,
      EVENT_CALENDAR_LIST_CULTURE_CHECK: `${REST_URL}/event-calendars/culture-check`,
      EVENT_CALENDAR_LIST_RESERVATION_CHECK: `${REST_URL}/event-calendars/reservation-check`,
      EVENT_CALENDAR_LIST_RESERVATION_CHECK_GOLF: `${REST_URL}/event-calendars/reservation-check/golf`,
    },
  },

  CALENDAR_DETAIL_ROOM: {
    HEADER_INFO: `${REST_URL}/calender-header-room/{id}`,
    ROOM_BOOKING_DETAIL: `${REST_URL}/room-calender-details`,
  },

  CALENDAR_DETAIL_GOLF: {
    HEADER_INFO: `${REST_URL}/calender-header-golf/{id}`,
    BOOKING_DETAIL: `${REST_URL}/golf-calender-details`,
    UPDATE_BOOKING_DETAIL: `${REST_URL}/golf-calender-details/{id}`,
    UPDATE_BULK_UPDATE: `${REST_URL}/golf-calender-bulk-update`,
  },

  CALENDAR_SCHEDULE: {
    CALENDAR_SCHEDULE_DETAIL: `${REST_URL}/calender-schedule-detail`,
    CALENDAR_SCHEDULE_MENU: `${REST_URL}/calendar-schedule-menus`,
    GOLF_CALENDAR_SCHEDULE_MENU: `${REST_URL}/golf-calendar-schedule-menus`,
    CALENDAR_SCHEDULE_MENU_INFO: `${REST_URL}/calendar-schedule-menus/{id}`,
    GOLF_CALENDAR_SCHEDULE_MENU_INFO: `${REST_URL}/golf-calendar-schedule-menus/{id}`,
    CALENDAR_SCHEDULE_DETAIL_INFO: `${REST_URL}/calender-schedule-detail/{id}`,
    CALENDAR_SCHEDULE_HEADER: `${REST_URL}/calender-schedule-header`,
    GOLF_CALENDAR_SCHEDULE_BOOKINGS: `${REST_URL}/golf-calender-schedule-bookings`,
    GOLF_CALENDAR_SCHEDULE_BOOKINGS_INFO: `${REST_URL}/golf-calender-schedule-bookings/{id}`,
  },

  SCHOOL: {
    SCHOOL_WEEK_SCHEDULE: {
      SCHOOL_WEEK_SCHEDULE_DETAIL: `${REST_URL}/school-schedule-details`,
      SCHOOL_WEEK_SCHEDULE_DETAIL_INFO: `${REST_URL}/school-schedule-details/{id}`,
    },
    SCHOOL_SCHEDULE_DETAIL: {
      SCHOOL_SCHEDULE_DETAIL: `${REST_URL}/school-schedule-details`,
      COPY_SCHEDULE_DETAIL: `${REST_URL}/school-schedules/copy`,
    },
    SCHOOL_SCHEDULE_LIST: {
      SELECTS: `${REST_URL}/school-schedules/selects`,
      SCHOOL_SCHEDULE_LIST: `${REST_URL}/school-schedules`,
      SCHOOL_SCHEDULE_HEADER_INFO: `${REST_URL}/school-schedules/{id}`,
    },
    SCHOOL_CALENDER: {
      SCHOOL_CALENDER_LIST: `${REST_URL}/school-calendars`,
      SCHOOL_CALENDAR_LIST_MODAL_DATA: `${REST_URL}/school-calendars/header`,
      SCHOOL_CALENDAR_LIST_WEEK_SCHEDULES: `${REST_URL}/school-calendars/week-schedules`,
      SCHOOL_CALENDAR_LIST_EXPERIENCER_CHECK: `${REST_URL}/school-calendars/experiencer-check`,
    },
    MEMBER_LINKAGE: {
      MEMBER_LINKAGE_INFO: `${REST_URL}/school-schedules/linkage/{id}`,
      MEMBER_LINKAGE_LIST: `${REST_URL}/school-schedules/linkage`,
      MEMBER_LIST: `${REST_URL}/school-schedules/linkage/members`,
    },
    WEEK_SELECT: {
      SELECTS: `${REST_URL}/week-selects/selects`,
      MEMBER_PRODUCT_INFO: `${REST_URL}/week-selects/info`,
      WEEK_SELECT_SCHEDULE: `${REST_URL}/week-selects`,
    },
  },

  INFO: {
    CLASS_INFOMATION: {
      CLASS_INFOMATION: `${REST_URL}/class-infos`,
      SELECTS: `${REST_URL}/class-infos/selects`,
    },
    TEACHER_CONTACT_TASK_LIST: {
      SELECTS: `${REST_URL}/teacher-contact-tasks/selects`,
      TEACHER_CONTACT_TASK_LIST: `${REST_URL}/teacher-contact-tasks`,
    },
    HEALTH_CHECK: {
      HEALTH_CHECK_ITEMS: `${REST_URL}/health-check-items`,
      HEALTH_CHECK: `${REST_URL}/health-checks`,
      MEMBER_INFO: `${REST_URL}/health-checks/member`,
    },
    ATTENDANCE_ENTRY: {
      ATTENDANCE_ENTRIES: `${REST_URL}/attendance-entries`,
      ATTENDANCE_ENTRY_SELECT: `${REST_URL}/attendance-entries/selects`,
    },
  },

  USER_SETTING: {
    SYSTEM_USER: {
      SYSTEM_USERS: `${REST_URL}/system-users`,
      SELECT_LIST: `${REST_URL}/select-system-users`,
      SYSTEM_USER: `${REST_URL}/system-users/{id}`,
      MAIL_CHECK: `${REST_URL}/system-users/mail-check`,
    },
    ROLE: {
      ROLES: `${REST_URL}/roles`,
      SCREENS: `${REST_URL}/screens`,
      ROLE: `${REST_URL}/roles/{id}`,
      ROLE_SCREENS: `${REST_URL}/role-screens`,
    },
  },

  SETTING: {
    SITE_SETTING: {
      SITE_SETTINGS: `${REST_URL}/site-settings/{id}`,
    },
    BANNER: {
      BANNERS: `${REST_URL}/banners`,
      BANNER_RELEASE: `${REST_URL}/banners/release/{id}`,
      BANNER_UPDATE: `${REST_URL}/banners/{id}`,
    },
    MAIL_LIST: {
      MAIL_LIST: `${REST_URL}/mails`,
      UPDATE_STOP_FLG: `${REST_URL}/mails/status/{id}`,
    },
    MAIL_DETAIL: {
      MAIL_DETAIL: `${REST_URL}/mails/{id}`,
    },
    RULE: {
      RULE: `${REST_URL}/rules`,
      CULTURE_RULE: `${REST_URL}/culture-rules`,
      GOLF_RULE: `${REST_URL}/golf-rules`,
      ROOM_RULE: `${REST_URL}/room-rules`,
      RENTAL_COURT_RULE: `${REST_URL}/rental-court-rules`,
      REGULAR_RULE: `${REST_URL}/regular-rules`,
      GOLF_TIMES: `${REST_URL}/golf-times`,
      ONLINE_CULTURE_RULE: `${REST_URL}/online-culture-rules`,
    },
    SURVEY_SETTING: {
      SURVEY_SETTING: `${REST_URL}/survey-settings`,
    },
    HOLIDAY: {
      HOLIDAYS: `${REST_URL}/holidays`,
    },
  },

  MASTER: {
    TEACHER_LIST: {
      TEACHER_LIST: `${REST_URL}/teachers`,
      PASSWORD: `${REST_URL}/teachers/password/{id}`,
    },
    NOTICE: {
      NOTICE: `${REST_URL}/notices`,
      NOTICE_SELECT: `${REST_URL}/notices/selects`,
      NOTICE_DETAIL: `${REST_URL}/notices/{id}`,
      NOTICE_COPY: `${REST_URL}/notices/copy`,
    },
  },
  MEMBER: {
    MEMBER_LIST: {
      MEMBER_LIST: `${REST_URL}/members`,
      MEMBER_PASSWORD: `${REST_URL}/change-member-password/{id}`,
      MEMBERS_CSV: `${REST_URL}/members/csv-datas`,
    },
    ENTRY: {
      ENTRY_SELECTS: `${REST_URL}/members/selects`,
    },
    DETAIL: {
      MEMBER_DETAIL: `${REST_URL}/members/member-detail`,
      MEMBER_DETAIL_CHECK: `${REST_URL}/members/member-detail/check-number`,
      MEMBER_DETAIL_WITHDRAWAL: `${REST_URL}/members/member-detail/withdrawal`,
      MEMBER_DETAIL_PRODUCT: `${REST_URL}/members/member-detail/product`,
      MEMBER_DETAIL_CONTRACT: `${REST_URL}/members/member-detail/contarct`,
    },
  },
};
